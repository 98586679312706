import "./Link.css";

type LinkProps = {
	href?: string;
	img?: string;
	text?: string;
}

function Link(props: LinkProps) {
	return (
		<div className="link">
			<a href={props.href} target="_blank" rel="noreferrer">
				{props.img && <img src={props.img} alt=""></img>}
				{props.text && <p>{props.text}</p>}
			</a>
		</div>
	);
}

export default Link;
