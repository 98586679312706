import "./Contact.css";
import Link from "../../atoms/link/Link";

function Contact() {
	return (
		<div className="contact">
			<Link img="images/icons/github.png" href="https://www.github.com/joaofsl"/>
			<Link img="images/icons/linkedin.png" href="https://www.linkedin.com/in/joaofsl/"/>
			<Link img="images/icons/mail.png" href="mailto:joaofsl1994@gmail.com"/>
		</div>
	);
}

export default Contact;
