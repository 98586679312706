import "./App.css";
import Navbar from "../../organisms/navbar/Navbar";
import Content from "../../organisms/content/Content";
import Footer from "../../atoms/footer/Footer";

function App() {
	const style = {backgroundImage: "url(images/textures/background.jpg)"};
	return (
		<div className="app">
			<div className="app__image" style={style}></div>
			<div className="app__content">
				<header>
					<Navbar />
				</header>
				<Content />
				<Footer />
			</div>
		</div>
	);
}

export default App;
