import "./Content.css";
import Hero from "../../molecules/hero/Hero";
import Projects from "../../molecules/projects/Projects";
import Contact from "../../molecules/contact/Contact";

function Content() {
	return (
		<div className="content">
			<section id="home">
				<Hero />
			</section>
			<section id="projects">
				<Projects />
			</section>
			<section id="contact">
				<Contact />	
			</section>
		</div>
	);
}

export default Content;
