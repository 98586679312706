import { useState } from "react";
import "./Hero.css";

function Hero() {
    const name: string = "João Leal";
	const description1: string = "Hi! I'm João Leal, a ";
	const description2: string = " engineer based in Portugal.";
    const img: string = "images/joao-leal-white-nobg.png";
    const imgHover: string = "images/joao-leal-nobg.png";

	const [hoverImg, setHoverImg] = useState(false);
	const onHoverImg = () => {
		setHoverImg(!hoverImg);
	};

	const languageOptions = [
		"javascript",
		"python",
		"vue",
		"react",
		"html5",
		"css",
		"django",
		"c++",
		"java",
		"mongodb",
		"node",
	];
	const [language, setLanguage] = useState(0);

	const [hover, setHover] = useState(false);
	const onHover = () => {
		setHover(!hover);
		if (!hover) {
			updateLanguage();
		}
	};

	const updateLanguage = () => {
		setLanguage(language === languageOptions.length - 1 ? 0 : language + 1);
	}

	const getLanguageImg = (): string => "images/skills/" + languageOptions[language] + ".svg";

	return (
		<div className="hero">
			<img className="hero__image" onMouseEnter={onHoverImg} onMouseLeave={onHoverImg} onMouseOut={onHoverImg} src={hoverImg ? imgHover : img} alt={name} />
			<div className="hero__info">
				<div className="hero__info__title">{name}</div>
				<p className="hero__info__description">
					{description1}
					{!hover && <span onMouseEnter={onHover}>software</span>}
					{hover && <img onClick={updateLanguage} onMouseLeave={onHover} onMouseOut={onHover} src={getLanguageImg()} alt={languageOptions[language]} />}
					{description2}
				</p>
			</div>
		</div>
	);
}

export default Hero;
