import "./Project.css";

export interface ProjectProps {
	title: string;
	description: string;
	img?: string;
}

function Project(props: ProjectProps) {
	return (
		<div className="project">
			<div className="project-wrapper">
				<div className="project-img">
					{props.img && <img src={props.img} alt=""></img>}
				</div>
				<div className="project-info">
					<div className="project-title">{props.title}</div>
					<div className="project-description">{props.description}</div>
				</div>
			</div>
		</div>
	);
}

export default Project;
