import "./Footer.css";

function Footer() {
	const year: number = new Date().getFullYear();
	const name: string = "João Leal";
	return (
		<div className="footer">
			<p>© {year} {name}</p>
		</div>
	);
}

export default Footer;
