import "./Navbar.css";
import { Link } from "react-scroll";

type NavbarItem = {
	name: string;
	link: string;
}

function Navbar() {
	const items: Array<NavbarItem> = [
		{
			name:"Home",
			link: "home"
		},
		{
			name:"Projects",
			link: "projects"
		},
		{
			name:"Contact",
			link: "contact"
		}
	];
	const name = "João Leal";
	
	return (
		<nav className="navbar">
			<div className="name"><img className="logo" src="images/icons/logo.png" alt="logo" />{name}</div>
			<div className="items">
				{items.map((item) =>
					<div className="item" key={item.name}>
						<Link smooth spy to={item.link}>{item.name}
						</Link>
					</div>
				)}
			</div>
		</nav>
	);
}

export default Navbar;
